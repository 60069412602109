<template>
  <router-view/>
  <Header v-if="showHeader"></Header>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  inject,
  toRefs,
  computed
} from 'vue';
import { useRoute } from 'vue-router';
import Header from '@/components/layout/Header.vue';
// 组件加载执行
onMounted(() => {
  // 初始化页面
  handleResize(true);
  // 监听各种数据
  window.addEventListener('resize', handleResize);
});
// 组件卸载执行
onUnmounted(() => {
  // 移除页面大小变化监听
  window.removeEventListener('resize', handleResize);
});


// 常用参数
const route = useRoute();
const commonUtils = inject('$commonUtils');
const device = ref(commonUtils.equipmentJudeg());

// 组件参数
const showHeader = computed(() => route.meta.showHeader !== false);


function handleResize (judeg) {
  if (device.value !== commonUtils.equipmentJudeg() || judeg) {
    device.value = commonUtils.equipmentJudeg();
    // 页面css
    commonUtils.whetherLoadCss('/static/dynamicCss/home-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/register-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/login-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/chatgpt-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/userinfo-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/picture_generation-pc.css');
    // 组件css
    commonUtils.whetherLoadCss('/static/dynamicCss/layout-pc.css');
    commonUtils.whetherLoadCss('/static/dynamicCss/toolkit-pc.css');
  }
}
</script>

<style scoped>
  @import "@/assets/css/app.css";
  @import "@/assets/iconfont/iconfont.css";
</style>
