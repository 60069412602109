<template>
  <!-- 组件 -->
  <Safety v-if="safetyControl" @safetyEmits="safetyMonitor"></Safety>
  <Inquiry v-if="inquiryData" :data="inquiryData" @inquiryEmits="inquiryMonitor"></Inquiry>
  <InputField v-if="inputFieldMessages" :message="inputFieldMessages" @inputEmits="inputMonitor"></InputField>
  <!--基层盒子-->
  <div class="chatgpt-shade">
    <!--遮罩-->
    <div class="chatgpt-mask" v-if="chatgptStyle.mask" @click="letfFarmeClose(true)"></div>
    <!--左侧栏-->
    <div class="chatgpt-left-frame-box" :style="chatgptStyle.leftFrameBox" @click="letfFarmeClose(false)">
      <div class="chatgpt-cushion-box">
        <div class="chatgpt-dialogue-box">
          <div class="chatgpt-dialogue-new-box">
            <div class="chatgpt-dialogue-new" @click="newDialogue">
              <span class="iconfont chatgpt-dialogue-gpt-logo">&#xe73a;</span>
              <p>新建对话</p>
              <span class="iconfont chatgpt-dialogue-new-logo">&#xe673;</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chatgpt-user-set-box" :style="chatgptStyle.userinfoBox">
        <div class="chatgpt-menu-set-box" v-if="chatgptStyle.userSetMenuSwitch">
          <div>
            <button class="chatgpt-user-config-button" @click="commonUtils.newSkip('/userinfo')">
              <span class="iconfont">&#xe7bc;</span>设置中心
            </button>
            <button class="chatgpt-user-config-button" @click="apikeyControl">
              <span class="iconfont">&#xe603;</span>配置Apikey
            </button>
            <button class="chatgpt-user-config-button" @click="commonUtils.userLogout">
              <span class="iconfont">&#xe65a;</span>注销登录
            </button>
          </div>
        </div>
        <div ref="userinfoRef" class="chatgpt-user-control-box" :style="chatgptStyle.userSetMenuBox" @click.stop="userSetControl(true)">
          <img class="chatgpt-user-head" draggable="false" :src="userhead" alt="">
          <p class="chatgpt-user-name">{{ username }}</p>
        </div>
      </div>
      <div class="chatgpt-history-dialoue-box">历史对话列表<Loading1 v-if="loading1Control" left="auto" right="10px"></Loading1>
      </div>
      <div ref="historyRef" class="chatgpt-history-box" @scroll="historyScrollMonitor">
        <div class="chatgpt-dialoue-case-box" v-for="(item, index) in session">
          <div :class="item.active ? item.active : 'chatgpt-dialogue'" :style="item.Dstyle" @click="dialogueBinding(item, index)" @mouseenter="dialogueMouseenter(item)" @mouseleave="dialogueMouseleave(item)">
            <input :id="item.did" :type="item.type" :style="item.Istyle" maxlength="50" v-model="item.title" @click.stop="inputContentBinding(item, index)" @blur.stop="updataDialogueTitleBlur(item, index)" @keyup.enter="updataDialogueTitleBlur(item, index)" />
            <div class="chatgpt-dialoue-case-menue-box" v-show="item.menu">
              <Dropdown class="chatgpt-dropdown" control="click" :selector="historyRef" @click.stop="dropDownBinding(item)">
                <template v-slot:menus>
                  <button class="iconfont icon-zhongmingming" @click="rewriteBinding(item, index)">重命名</button>
                  <button class="iconfont icon-shanchu" @click="deleteBinding(item, index)">删除</button>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chatgpt-farme-left-close-control-box" v-if="chatgptStyle.leftFarmeCloseShow" :style="chatgptStyle.leftFarmeCloseSwitch" @click.stop="letfFarmeClose(true)">
      <div><span class="iconfont">&#xe65d;</span></div>
    </div>
    <!-- v-loading="loadingModule" element-loading-background="rgba(220, 221, 225,0.1)" -->
    <div class="chatgpt-subject-right-box" @click.stop="letfFarmeClose(true)">
      <Message v-model:message="messageData" left="50%" top="100px"></Message>
      <!--左栏开关-->
      <div class="chatgpt-farme-left-control-big-box" v-if="chatgptStyle.leftFarmeSwitch" @click.stop="leftFrameSwitch">
        <span class="chatgpt-farme-left-switch1" :style="chatgptStyle.switch1"></span>
        <span class="chatgpt-farme-left-switch2" :style="chatgptStyle.switch2"></span>
      </div>
      <!--上栏-->
      <div class="chatgpt-right-top-head-box">
        <div class="chatgpt-farme-left-switch-control-box" v-if="chatgptStyle.leftFarmeSwitchControl" @click.stop="letfFarmeBinding">
          <span class="iconfont chatgpt-farme-left-switch-control">&#xee15;</span>
        </div>
        <div class="chatgpt-right-top-model-select-box" :style="chatgptStyle.modelBox">
          <div class="chatgpt-right-top-model-box" :style="chatgptStyle.modelNameBox" @click.stop="modelSelect">
            <div class="chatgpt-right-top-model-name">
              <p :style="chatgptStyle.modelName">{{ modelNameShow }}</p>
            </div>
            <div class="chatgpt-choice-box" :style="chatgptStyle.choiceBox">
              <button v-for="(item, index) in modelNameList" @click="getModel(item)" v-text="item.alias"></button>
            </div>
          </div>
        </div>
        <div class="chatgpt-right-top-skip-box">
          <button class="chatgpt-skip-login" v-if="chatgptStyle.userSkipLogin" type="button" @click="commonUtils.thisSkip(router, '/login')">登录</button>
          <div class="chatgpt-skip-home" v-if="chatgptStyle.access_box" @click="commonUtils.thisSkip(router, '/')">
            <span class="iconfont chatgpt-skip-home-icon">&#xe65e;</span>
          </div>
        </div>
      </div>
      <!--对话区域-->
      <div ref="dialogueMessageRef" class="chatgpt-dialogue-message-box" :style="chatgptStyle.dialogueMessageRef" @scroll="messageMonitor" @wheel.passive="messageMouseMonitor" @touchstart.passive="messageStartMonitor" @touchmove.passive="messageStopMonitor">
        <div class="chatgpt-silde-bottom-button" :style="chatgptStyle.sildeBottom" v-if="sildeBottom" @click.stop="slideBottom">
          <span class="iconfont">&#xe6dd;</span>
        </div>
        <div class="chatgpt-message-home-box" v-if="messageHomeLogo">
          <div class="chatgpt-message-home">
            <div class="universal-logo">
              <span></span>
              <img src="/static/images/logo/chatgpt_logo.png" alt="" draggable="false">
              <div class="universal-circle-breath-one"></div>
              <div class="universal-circle-breath-two"></div>
            </div>
            <h1 class="chatgpt-message-home-hint">GPT也许可以为你提供一些帮助</h1>
          </div>
        </div>
        <!--会话框-->
        <div class="chatgpt-message-packaging-box" :style="chatgptStyle.messagePackagingBox">
          <div class="chatgpt-message-packaging">
            <div class="chatgpt-packaging-box" v-for="(item, index) in dialogueRecord">
              <!-- 用户对话框 -->
              <div class="chatgpt-user-chat-1">
                <div class="chatgpt-user-chat-2">
                  <div class="chatgpt-user-chat-6">
                    <div>
                      <p class="chatgpt-user-chat-name">YOU</p>
                      <p class="chatgpt-user-chat-time">{{ item.userTime }}</p>
                    </div>
                  </div>
                  <div class="chatgpt-user-chat-3">{{ item.userContent }}</div>
                </div>
                <div class="chatgpt-user-chat-4"></div>
                <img class="chatgpt-user-chat-5" :src="userhead" alt="" draggable="false">
              </div>
              <!-- chatgpt对话封装 -->
              <div class="chatgpt-gpt-chat-1">
                <img class="chatgpt-gpt-chat-2" src="/static/images/logo/chatgpt_logo.png" alt="" draggable="false">
                <div class="chatgpt-gpt-chat-3"></div>
                <div class="chatgpt-gpt-chat-4">
                  <div class="chatgpt-gpt-chat-6">
                    <div>
                      <p class="chatgpt-gpt-chat-name">ChatGPT</p>
                      <p class="chatgpt-gpt-chat-time">{{ item.gptTime }}</p>
                    </div>
                  </div>
                  <div :id="item.gptId" class="chatgpt-gpt-chat-5" v-html="item.gptContent"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--下栏-->
      <div ref="bottomBoxRef" class="chatgpt-right-bottom-box" @click="letfFarmeClose(true)">
        <div class="chatgpt-right-bottom-hint-box">
          <el-tooltip :content="websocketHint" placement="top" effect="dark">
            <img :src="modleLight" alt="" draggable="false" @click.stop="websocketRect">
          </el-tooltip>
          <Loading v-if="loadingControl" left="25px" top="auto" bottom="-28.5px" right="auto" zIndex="999"></Loading>
        </div>
        <div class="chatgpt-right-bottom-input-box">
          <el-tooltip content="Shift+Enter输入回车" placement="top-start" effect="dark" :visible="visibleTextarea">
            <textarea ref="inputContentRef" class="chatgpt-input-content-box" v-model="inputContent" rows="1" contenteditable="true" placeholder="发送消息给 ChatGPT..." @input="inputContentMonitor" @keydown.enter="lineFeed" @focus="visibleTextarea = device !== 'PC' ? false : true" @blur="visibleTextarea = false"></textarea>
          </el-tooltip>
        </div>
        <div class="chatgpt-right-bottom-send-box">
          <el-tooltip :content="sendHint" placement="top" effect="dark" :visible="sendHint !== null">
            <button type="button" :style="chatgptStyle.sendButton" @click.stop="sendWebsocket">SEND</button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // 常用导入
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    nextTick
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    nextTick(() => {
      pageInitialize();
      mutationObserver();
      userWhetherLogin(true);

    });
    commonUtils.addPageIdentification(pageRandom);
    window.addEventListener('click', pageResetDataAll);
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('click', pageResetDataAll);
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  // 常量
  const router = useRouter();
  const commonUtils = inject('$commonUtils');
  const axios = inject('$axiosTkxt');
  const axiosApi = inject('$axiosApi');
  const jquery = inject('$jquery');
  const cookie = inject('$cookie');
  const websocket = inject('$websocket');
  const markdownit = inject('$markdownIt');
  const highlight = inject('$highlight');
  const clipboard = inject('$clipboard');
  const hostServe = inject('$hostServe');
  const resourceApi = inject('$resourceApi');
  const AESUtils = inject('$AESUtils');
  const pageRandom = 'ChatGpt-' + commonUtils.getRandomInteger(10) + commonUtils.getRandomString(5);

  // 变量
  let device = commonUtils.equipmentJudeg();
  let windWidth = window.innerWidth;
  let userhead = ref(require('@/assets/image/system/user_head_default.png'));
  let modleLight = ref(require('@/assets/image/system/light0.png'));
  let dialogueList = ref(cookie.get('Dialogue'));
  let loadingControl = ref(false)
  let dialogueRecord = ref([]);
  let userinfoRef = ref(null)
  let dialogueIndex = dialogueRecord.value.length;
  let leftFrameBoxSize = false;
  let listFrameBox = true;
  let dialogueMessageRef = ref(null);
  let username = ref('YOU');
  let loadingModule = ref(null);
  let webSocketTimeout = ref(null);
  let inputContentRef = ref(null);
  let inputContent = ref('');
  let messageHomeLogo = ref(true);
  let transferStatus = false;
  let modeType = null;
  let modelNameShow = ref('ChatGpt');
  let modelNameList = ref([]);
  let session = ref([]);
  let dialogueInputJudeg = true;
  let dialogueTitleList = null;
  let inquiryData = ref(null);
  let redisDialogueData = '';
  let mouseWheel = false;
  let messcroll = false;
  let startX = 0;
  let startY = 0;
  let sildeBottom = ref(false);
  let bottomBoxRef = ref(null);
  let visibleTextarea = false;
  let sendHint = ref(null);
  let sendHintTimeout = null;
  let loading1Control = ref(false);
  let websocketHint = ref('等待');
  let pageStrip = 0;
  let historyRef = ref(null);
  let messageData = ref(null);
  let inputFieldMessages = ref(null);
  let safetyControl = ref(false);
  let jurisdictionTime = null;
  let chatgptStyle = ref({
    userSkipLogin: true,
    mask: false,
    leftFarmeSwitch: null,
    leftFarmeCloseSwitch: null,
    leftFarmeCloseShow: false,
    switch1: { 'transform': null },
    switch2: { 'transform': null },
    leftFarmeSwitchControl: null,
    leftFrameBox: { 'min-width': null, 'transition': null, 'position': null, 'width': null },
    modelBox: { 'justify-content': null },
    modelNameBox: { 'width': null },
    modelName: { 'font-size': null },
    choiceBox: { 'font-size': null, 'height': 0 },
    messagePackagingBox: { 'width': null },
    chatgptChat1: { 'border-left': null },
    userChat1: { 'border-right': null },
    userSetMenuSwitch: false,
    userSetMenuBox: { 'background-color': null, 'margin-top': null },
    userinfoBox: { 'height': null },
    sendButton: { 'border': null, 'color': null },
    dialogueMessageRef: { 'scroll-behavior': null, 'height': null },
    sildeBottomBox: { 'margin-bottom': null }
  });

  /**
   * 页面路由时执行
   */
  router.beforeEach((to, from, next) => {
    handleBeforeUnload();
    next();
  });


  /**
   * 页面刷新或关闭关闭时
   */
  function handleBeforeUnload() {
    commonUtils.removePageIdentification(pageRandom);
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    device = commonUtils.equipmentJudeg();
    pageInitialize();
    historyScrollMonitor();
  }

  /**
   * MutationObserver监听
   */
  function mutationObserver() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(function() {
        const mesgH = 53 + bottomBoxRef.value.clientHeight;
        chatgptStyle.value.sildeBottomBox['margin-bottom'] = mesgH - 53 + 'px';
        chatgptStyle.value.dialogueMessageRef.height = 'calc(100% - ' + mesgH + 'px)';
        sildeBottom.value = !(dialogueMessageRef.value.scrollHeight - (dialogueMessageRef.value.clientHeight + dialogueMessageRef.value.scrollTop) < 1);
        // 初始化滑动底部按键
        if (mouseWheel) {
          dialogueMessageRef.value.scrollTop = dialogueMessageRef.value.scrollHeight;
        } else if (messcroll && dialogueMessageRef.value.scrollHeight - (dialogueMessageRef.value.clientHeight + Math.ceil((dialogueMessageRef.value.scrollTop))) <= 100) {
          mouseWheel = true;
          dialogueMessageRef.value.scrollTop = dialogueMessageRef.value.scrollHeight;
        }
      });
    });
    observer.observe(inputContentRef.value, { attributes: true, childList: true, characterData: true, subtree: true });
    observer.observe(dialogueMessageRef.value, { attributes: true, childList: true, characterData: true, subtree: true });
  }

  /**
   * 页面初始化与
   */
  function pageInitialize() {
    if (windWidth >= 800) {
      chatgptStyle.value.mask = false;
      chatgptStyle.value.leftFarmeCloseShow = false;
      chatgptStyle.value.leftFarmeCloseSwitch = { 'margin-left': '-50px' };
      chatgptStyle.value.leftFarmeSwitch = true;
      chatgptStyle.value.leftFarmeSwitchControl = false;
      chatgptStyle.value.modelBox['justify-content'] = 'center';
      chatgptStyle.value.modelNameBox.width = '85%';
      if (listFrameBox === true) {
        if (leftFrameBoxSize === true) {
          chatgptStyle.value.switch1.transform = null;
          chatgptStyle.value.switch2.transform = null;
        }
        chatgptStyle.value.leftFrameBox['min-width'] = '260px';
      }
      leftFrameBoxSize = false;
      chatgptStyle.value.leftFrameBox.position = 'relative';
      chatgptStyle.value.messagePackagingBox.width = '80%';
      chatgptStyle.value.userChat1['border-left'] = '1px solid rgba(87, 101, 116,0.3)';
      chatgptStyle.value.chatgptChat1['border-right'] = '1px solid rgba(87, 101, 116,0.3)';
    } else {
      chatgptStyle.value.leftFarmeSwitch = false;
      chatgptStyle.value.leftFarmeCloseShow = true;
      chatgptStyle.value.leftFarmeSwitchControl = true;
      if (leftFrameBoxSize === false) {
        chatgptStyle.value.switch1.transform = 'rotate(-20deg)';
        chatgptStyle.value.switch2.transform = 'rotate(20deg)';
        chatgptStyle.value.leftFrameBox['min-width'] = 0;
        chatgptStyle.value.modelBox['justify-content'] = 'left';
        chatgptStyle.value.modelNameBox.width = '95%';
        session.value.forEach(item => {
          if (!item.dropDownJudeg) {
            document.body.click()
            return
          }
        });
      }
      leftFrameBoxSize = true;
      chatgptStyle.value.messagePackagingBox.width = '100%';
      chatgptStyle.value.userChat1 = { 'border-right': 'none' };
      chatgptStyle.value.chatgptChat1 = { 'border-left': 'none' };
    }
    chatgptStyle.value.leftFrameBox.transition = 'none';
    chatgptStyle.value.modelName['font-size'] = '15px';
    chatgptStyle.value.choiceBox['font-size'] = '13px';
  }

  /**
   * 左侧栏开关
   */
  function leftFrameSwitch() {
    if (windWidth >= 800) {
      chatgptStyle.value.leftFrameBox.position = 'relative';
      chatgptStyle.value.leftFrameBox.transition = 'min-width 0.1s ease-in-out';
      if (chatgptStyle.value.leftFrameBox['min-width'] === 0) {
        listFrameBox = true;
        chatgptStyle.value.switch1.transform = null;
        chatgptStyle.value.switch2.transform = null;
        chatgptStyle.value.leftFrameBox['min-width'] = '260px';
      } else {
        listFrameBox = false;
        chatgptStyle.value.switch1.transform = 'rotate(-20deg)';
        chatgptStyle.value.switch2.transform = 'rotate(20deg)';
        chatgptStyle.value.leftFrameBox['min-width'] = 0;
      }
    } else {
      chatgptStyle.value.leftFrameBox.position = 'absolute';
      chatgptStyle.value.leftFrameBox.transition = 'min-width 0.3s ease-in-out';
      if (chatgptStyle.value.leftFrameBox['min-width'] === 0) {
        listFrameBox = true;
        chatgptStyle.value.switch1.transform = null;
        chatgptStyle.value.switch2.transform = null;
        chatgptStyle.value.mask = true;
        chatgptStyle.value.leftFarmeCloseSwitch = { 'margin-left': '300px' };
        chatgptStyle.value.leftFrameBox['min-width'] = '300px';
      } else {
        listFrameBox = false;
        chatgptStyle.value.switch1.transform = 'rotate(-20deg)';
        chatgptStyle.value.switch2.transform = 'rotate(20deg)';
        chatgptStyle.value.mask = false;
        chatgptStyle.value.leftFarmeCloseSwitch = { 'margin-left': '-50px' };
        chatgptStyle.value.leftFrameBox['min-width'] = 0;
      }
    }
  }

  /**
   * 用户登录持久化
   * @param {是否重连websocket} judeg
   */
  function userWhetherLogin(judeg) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      chatgptStyle.value.userSkipLogin = false;
      chatgptStyle.value.access_box = true;
      username.value = userinfo.nickname;
      userhead.value = resourceApi + userinfo.head_portrait;
      if (judeg) {
        showModelAllAxios();
        showDialogueAllAxios();
        showDialogueAxios(dialogueList.value, false);
        websocket_connect(userinfo.username, userinfo.uid);
      }
    } else {
      if (websocket.value && websocket.value.readyState === 1) {
        websocket.value.close();
      }
    }
  }

  /**
   * websoket连接
   * @param {用户名} username
   * @param {用户id} uid
   */
  function websocket_connect(username, uid) {
    if (window.WebSocket) {
      websocket.value = new WebSocket(hostServe + '/' + username + '/' + uid);
      websocket.value.onopen = function(event) {
        websocketHint.value = '正常';
        modleLight.value = require('@/assets/image/system/light1.png');
        commonUtils.elmentInform('WebSocket', '连接成功！', 'success', 2000);
      };
      websocket.value.onerror = function(event) {
        websocketHint.value = '重试';
        modleLight.value = require('@/assets/image/system/light2.png');
        commonUtils.elmentInform('WebSocket', '连接失败！', 'error', 2000);
      };
      websocket.value.onclose = function() {
        websocketHint.value = '重连';
        modleLight.value = require('@/assets/image/system/light3.png');
        commonUtils.elmentInform('WebSocket', '连接断开！', 'warning', 2000);
      };
    } else {
      commonUtils.elmentMessage('浏览器不支持此通讯', 'warning', 60);
    }
  }

  /**
   * websocket重新连接
   */
  function websocketRect() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      if (!websocket.value || websocket.value.readyState !== 1) {
        websocket_connect(userinfo.username, userinfo.uid);
      } else if (transferStatus) {
        initialize(true, true, true, true, true);
      } else {
        commonUtils.elmentInform('WebSocket', '通讯正常！', 'info', 2000);
      }
    } else {
      commonUtils.elmentMessage('请先登录', 'info', 60);
    }
  }

  /**
   * 其他位置关闭左侧栏
   * @param {是否只控制关闭} judeg
   */
  function letfFarmeClose(judeg) {
    if (judeg && windWidth <= 800 && chatgptStyle.value.leftFrameBox['min-width'] !== 0) {
      leftFrameSwitch();
    }
    userSetControl(false);
    chatgptStyle.value.choiceBox.height = 0;
  }

  /**
   * 左侧栏下用户信息按钮事件
   * @param {是否只控制关闭} judeg
   */
  function userSetControl(judeg) {
    document.body.click()
    if (commonUtils.emptyCookie('Userinfo') && !chatgptStyle.value.userSetMenuSwitch && judeg) {
      chatgptStyle.value.userSetMenuSwitch = true;
      chatgptStyle.value.userSetMenuBox['margin-top'] = 0;
      chatgptStyle.value.userSetMenuBox['background-color'] = 'rgba(53, 59, 72,0.8)';
      chatgptStyle.value.userinfoBox.height = '250px';
    } else {
      chatgptStyle.value.userSetMenuSwitch = false;
      chatgptStyle.value.userSetMenuBox['margin-top'] = '10px';
      chatgptStyle.value.userSetMenuBox['background-color'] = null;
      chatgptStyle.value.userinfoBox.height = '80px';
    }
  }

  /**
   * 上栏模型选择按钮
   */
  function modelSelect() {
    const userinfo = commonUtils.emptyCookie('Userinfo', '请先登录');
    if (userinfo && chatgptStyle.value.choiceBox.height === 0) {
      chatgptStyle.value.choiceBox.height = '160px';
    } else {
      chatgptStyle.value.choiceBox.height = 0;
    }
  }

  /**
   * apikey修改配置按钮
   */
  function apikeyControl() {
    if (jurisdictionTime !== null) {
      userSetControl(false);
      inputFieldMessages.value = "配置Apikey"
    } else {
      safetyControl.value = true;
    }
  }

  /**
   * apikey输入框点击结果回响接收
   * @param {apikey值} params
   */
  function inputMonitor(params) {
    if (params.judeg) {
      if (params.data && /^.+$/.test(params.data)) {
        setApikeyAxios(params.data);
      } else {
        commonUtils.elmentMessage('Apikey不为空', 'warning', 60);
      }
    } else {
      inputFieldMessages.value = null;
    }
  }

  /**
   * 处理输入框换行按钮
   * @param {event} event
   */
  function lineFeed(event) {
    if (!event.shiftKey && device !== 'MT') {
      event.preventDefault();
      sendWebsocket();
    }
  }

  /**
   * 聊天推送
   */
  async function sendWebsocket() {
    inputContentRef.value.focus();
    visibleTextarea = false;
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      if (websocket.value && websocket.value.readyState === 1) {
        if (!userinfo.apikey) {
          commonUtils.elmentMessage('未配置apikey', 'warning', 60);
          return;
        }
        if (transferStatus) {
          sendTs('正在请求', 2000);
          return;
        }
        if (/^\s*$/.test(inputContent.value)) {
          sendTs('空白内容', 2000);
          return;
        }
        loadingControl.value = true;
        sendHintTimeout = setTimeout(() => { sendHint.value = null; }, 2000);
        // 判断是否是创建新的对话
        let judeg = true;
        if (!dialogueList.value) {
          const formData = new FormData();
          formData.append('uid', userinfo.uid);
          if (await axiosApi.routine('/chatgpt/judeg_new_dialogue', 'POST', true, formData, false)) {
            judeg = false;
            dialogueList.value = commonUtils.getRandomString(10) + new Date().getTime();
            const dialogueData = {
              title: inputContent.value,
              did: dialogueList.value,
              Dstyle: null,
              Istyle: { border: '1px solid transparent' },
              type: 'button',
              active: null,
              menu: false,
              dropDownJudeg: true
            };
            establishDialogue(dialogueData, true);
            cookie.set('Dialogue', dialogueList.value);
            const content = inputContent.value;
            formData.append('username', userinfo.username);
            formData.append('title', content.slice(0, 50));
            formData.append('did', dialogueList.value);
            axiosApi.routine('/chatgpt/new_dialogue', 'POST', true, formData, false).then(result => { sendAll(content); });
          } else {
            commonUtils.elmentMessage('会话列表超出上限', 'warning', 60);
            return;
          }
        }
        // 添加上下文对话框
        messageHomeLogo.value = false;
        mouseWheel = true;
        transferStatus = true;
        websocketHint.value = '中断';
        dialogueRecord.value.push({
          userTime: new Date().toLocaleString(),
          userContent: inputContent.value,
          gptTime: '--',
          gptContent: '<span class="borderDom"><span class="middleLine"></span></span>',
          gptId: commonUtils.getRandomString(5) + new Date().getTime()
        });
        if (judeg) { sendAll(inputContent.value); }
      } else {
        commonUtils.elmentMessage('通讯未连接', 'warning', 60);
      }
    } else {
      commonUtils.elmentMessage('请先登录', 'info', 60);
    }
    inputContent.value = '';
    chatgptStyle.value.sendButton.color = 'rgba(75, 75, 75,0.3)';
    chatgptStyle.value.sendButton.border = '1px solid rgba(75, 75, 75,0.1)';
    nextTick(() => {
      jquery('.chatgpt-input-content-box').css('height', 'auto');
      jquery('.chatgpt-input-content-box').css('height', inputContentRef.value.scrollHeight - 16 + 'px');
    });
  }

  /**
   * 判断请求类型
   * @param {请求内容} content
   */
  function sendAll(content) {
    if (modeType === 'text') {
      sendText(content);
    } else if (modeType === 'image') {
      sendImage(content);
    } else {
      commonUtils.elmentMessage('暂未开发此类模型', 'info', 60);
      jquery('#' + dialogueRecord.value[dialogueIndex].gptId).text('暂未开发此类模型！');
      initialize(true, true, true, false, false);
    }
  }

  /**
   * 发送按钮提示
   * @param {提示文本} ts
   * @param {提示时间} time
   */
  function sendTs(ts, time) {
    sendHint.value = ts;
    if (sendHintTimeout) {
      clearTimeout(sendHintTimeout);
    }
    sendHintTimeout = setTimeout(() => {
      sendHint.value = null;
    }, time);
  }

  /**
   * 发送文本请求
   * @param {请求内容} content
   */
  function sendText(content) {
    if (websocket.value && websocket.value.readyState === 1) {
      websocket.value.send(content);
    } else {
      commonUtils.elmentMessage('通讯异常', 'warning', 60);
      return;
    }
    let gptData = '';
    const randM = commonUtils.getRandomString(10) + new Date().getTime();
    websocket.value.onmessage = function(event) {
      if (event.data === '[WRONG]') {
        initialize(true, true, true, false, false);
      } else if (event.data === '[DONE]') {
        initialize(true, true, true, true, false);
      } else {
        redisDialogueData += event.data;
        gptData += event.data;
        const md = markdownit({
          highlight: function(str, lang) {
            let title;
            let codeStr;
            let codeID = commonUtils.getRandomString(10) + new Date().getTime();
            if (highlight.listLanguages().includes(lang)) {
              title = lang;
            } else {
              title = '';
              lang = 'plaintext';
            }
            if (lang && highlight.getLanguage(lang)) {
              codeStr = highlight.highlight(str, {
                language: lang,
                ignoreIllegals: true
              }).value;
            } else {
              codeStr = md.utils.escapeHtml(str);
            }
            try {
              return '<pre><div class="chatgpt-copy-code-box"><div class="chatgpt-copy-code-button-box"><span class="chatgpt-copy-code-name">' + title + '</span>' +
                '<div class="chatgpt-copy-code-button ' + randM + '" data-clipboard-target="#codeText' + codeID + '">' +
                '<span class="iconfont">&#xe8c9;</span>Copy</div></div><div class="code_hljs_box">' +
                '<code class="highlight chatgpt-highlight language-' + lang + ' hljs" id="codeText' + codeID + '">' + codeStr + '</code></div></div></pre>';
              codeID = commonUtils.getRandomString(10) + new Date().getTime();
            } catch (__) {}
          },
          html: true,
          linkify: true,
          typographer: true
        });
        modleLight.value = modleLight.value === require('@/assets/image/system/light0.png') ? require('@/assets/image/system/light4.png') : require('@/assets/image/system/light0.png');
        jquery('#' + dialogueRecord.value[dialogueIndex].gptId).html(md.render(gptData));
        jquery('#' + dialogueRecord.value[dialogueIndex].gptId).children().last().append('<span class="iconfont flicker" style="color: rgb(' + commonUtils.getRandom(0, 255) + ', ' + commonUtils.getRandom(0, 255) + ', ' + commonUtils.getRandom(0, 255) + '">&#xe711;</span>');
      }
      nextTick(() => { copyText(randM); });
    };
  }

  /**
   * 请求图片
   * @param {请求内容} content
   */
  function sendImage(content) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    const formData = new FormData();
    formData.append('uid', userinfo.uid);
    formData.append('username', userinfo.username);
    formData.append('content', content);
    formData.append('params', JSON.stringify({ requestType: 'config' }));
    axiosApi.routine('/chatgpt/image/generations', 'POST', true, formData, false)
      .then(result => {
        if (result && result.error) {
          let image = '<img style="width: 100%;height: 100%;" src="' + result[0].url + '" alt="" draggable="false">';
          if (result[0].revised_prompt !== null && result[0].revised_prompt !== undefined) {
            image += '<p>' + result[0].revised_prompt + '</p>';
          }
          dialogueRecord.value[dialogueIndex].gptContent = image;
        } else {
          dialogueRecord.value[dialogueIndex].gptContent = result.error;
        }
        initialize(true, true, false, false, false);
      });
  }

  /**
   * 格式化数据数据库持久化
   * @param {是否写入时间} judegTime
   * @param {是否保存文本到数据库} judegSave
   * @param {是否写入文本到实际DOM} judegText
   * @param {是否保存文本到缓存} judegRedis
   * @param {是否重连websocket} judegWebsocket
   */
  function initialize(judegTime, judegSave, judegText, judegRedis, judegWebsocket) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    const flicker = document.getElementsByClassName('flicker');
    for (let i = 0; i < flicker.length; i++) {
      flicker[i].remove();
    }
    const borderDom = document.getElementsByClassName('borderDom');
    for (let i = 0; i < borderDom.length; i++) {
      borderDom[i].remove();
    }
    if (judegWebsocket) {
      websocket.value.close();
      websocket_connect(userinfo.username, userinfo.uid);
    }
    // 数据写入
    if (judegTime) { dialogueRecord.value[dialogueIndex].gptTime = new Date().toLocaleString(); }
    if (judegText) { dialogueRecord.value[dialogueIndex].gptContent = jquery('#' + dialogueRecord.value[dialogueIndex].gptId).html(); }
    if (judegSave) { saveDialogueAxios(dialogueList.value, judegRedis); }
    // 数据格式化
    redisDialogueData = '';
    transferStatus = false;
    dialogueIndex = dialogueRecord.value.length;
    if (websocket.value && websocket.value.readyState === 1) {
      websocketHint.value = '正常';
      modleLight.value = require('@/assets/image/system/light1.png');
    } else {
      websocketHint.value = '重连';
      modleLight.value = require('@/assets/image/system/light3.png');
    }
    loadingControl.value = false
  }

  /**
   * 保存对话到数据库
   * @param {生成记录id} did
   * @param {是否保存到redis缓存} judeg
   */
  function saveDialogueAxios(did, judeg) {
    const formData = new FormData();
    const redisData = judeg ? dialogueRecord.value[dialogueIndex].userContent + redisDialogueData : '';
    formData.append('dialogueArr', JSON.stringify(dialogueRecord.value[dialogueIndex]));
    formData.append('jointDialogue', redisData);
    formData.append('did', did);
    axiosApi.routine('/chatgpt/inset_dialogue', 'POST', true, formData, false);
  }

  /**
   * 为代码块绑定复制
   * @param {DOM元素} element
   */
  function copyText(element) {
    const copy = document.getElementsByClassName(element);
    for (let i = 0; i < copy.length; i++) {
      const clipboardJS = new clipboard(copy[i]);
      clipboardJS.on('success', function(e) {
        messageData.value = "复制成功";
        e.clearSelection();
      });
      clipboardJS.on('error', function(e) {
        messageData.value = "复制失败";
      });
    }
  }

  /**
   * 滑动到底部
   */
  function slideBottom() {
    chatgptStyle.value.dialogueMessageRef['scroll-behavior'] = 'smooth';
    nextTick(() => {
      messcroll = true;
      dialogueMessageRef.value.scrollTop = dialogueMessageRef.value.scrollHeight;
    });
  }

  /**
   * 监听对话框滑动
   */
  function messageMonitor() {
    nextTick(() => {
      messcroll = false;
      if (dialogueMessageRef.value.scrollHeight - (dialogueMessageRef.value.clientHeight + dialogueMessageRef.value.scrollTop) < 1) {
        messcroll = true;
        mouseWheel = true;
        sildeBottom.value = false;
        chatgptStyle.value.dialogueMessageRef['scroll-behavior'] = null;
      } else {
        mouseWheel = false;
        sildeBottom.value = true;
      }
      cookie.set('HeightScroll', dialogueMessageRef.value.scrollTop);
    });
  }

  /**
   * 监听对话展示区鼠标滚动
   */
  function messageMouseMonitor() {
    nextTick(() => {
      mouseWheel = false;
      messcroll = dialogueMessageRef.value.scrollHeight - (dialogueMessageRef.value.clientHeight + dialogueMessageRef.value.scrollTop) > 1;
    });
  }

  /**
   * 监听对话展示区鼠标触摸开始
   * @param {event} event
   */
  function messageStartMonitor(event) {
    nextTick(() => {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      mouseWheel = false;
      messcroll = false;
    });
  }

  /**
   * 监听对话展示区鼠标触摸结束
   * @param {event}
   */
  function messageStopMonitor(event) {
    nextTick(() => {
      const deltaX = event.touches[0].clientX - startX;
      const deltaY = event.touches[0].clientY - startY;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // 水平方向的位移大于垂直方向的位移，判断为水平滑动
      } else {
        // 垂直方向的位移大于水平方向的位移，判断为垂直滑动
        mouseWheel = false;
        messcroll = dialogueMessageRef.value.scrollHeight - (dialogueMessageRef.value.clientHeight + dialogueMessageRef.value.scrollTop) > 1;
      }
    });
  }

  /**
   * 获取所有模型
   * @param {数据集} item
   */
  function getModel(item) {
    if (item.alias !== modelNameShow.value) {
      modeType = item.type;
      modelNameShow.value = item.alias;
      setModelAxios(item);
    }
  }

  /**
   * 显示所有模型
   */
  function showModelAllAxios() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      loadingModule.value = true;
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      formData.append('username', userinfo.username);
      axiosApi.routine('/chatgpt/inquire_model_all', 'POST', true, formData, false)
        .then(result => {
          if (result) {
            modeType = result.modeJson.type;
            modelNameShow.value = result.modeJson.alias;
            modelNameList.value = result.modeList;
          }
          loadingModule.value = false;
        }).catch(error => { loadingModule.value = false; });
    }
  }

  /**
   * 选中模型
   * @param {Object} modeInfo
   */
  function setModelAxios(modeInfo) {
    loadingModule.value = true;
    const userinfo = commonUtils.emptyCookie('Userinfo');
    const formData = new FormData();
    formData.append('uid', userinfo.uid);
    formData.append('username', userinfo.username);
    for (const key in modeInfo) {
      formData.append(key, modeInfo[key]);
    }
    axiosApi.routine('/chatgpt/set_model', 'POST', true, formData, false)
      .then(result => { loadingModule.value = false; })
      .catch(error => { loadingModule.value = false; });
  }

  /**
   * apikey修改请求
   * @param {apikey值} keyData
   */
  async function setApikeyAxios(keyData) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    const formData = new FormData();
    formData.append('uid', userinfo.uid);
    formData.append('username', userinfo.username);
    formData.append('apikey', await AESUtils.encrypt(keyData, 'stair'));
    axiosApi.routine('/user/config_apikey', 'POST', true, formData, false)
      .then(result => {
        if (result === 1) {
          commonUtils.elmentMessage('配置成功', 'success', 60);
          inputFieldMessages.value = null;
          userinfo.apikey = commonUtils.substitution(5, keyData.length - 6, keyData, '*');
          cookie.set('Userinfo', encodeURI(JSON.stringify(userinfo)));
        } else {
          commonUtils.elmentMessage('配置失败', 'info', 60);
        }
        loadingModule.value = false;
      }).catch(error => { loadingModule.value = false; });
  }

  /**
   * 监听历史对话框滑动scroll
   */
  function historyScrollMonitor() {
    const scrollTop = historyRef.value.scrollTop;
    const scrollHeight = historyRef.value.scrollHeight;
    const clientHeight = historyRef.value.clientHeight;
    if (scrollTop + clientHeight + 5 >= scrollHeight) {
      showDialogueAllAxios();
    }
  }

  /**
   * 获取历史所有记录
   */
  function showDialogueAllAxios() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      loading1Control.value = true;
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      formData.append('strip', pageStrip);
      axiosApi.routine('/chatgpt/inquire_dialogue_all', 'POST', true, formData, false)
        .then(result => {
          if (result) {
            pageStrip += result.length;
            establishDialogue(result, false);
          }
        }).catch(error => { loading1Control.value = false; });
    }
  }

  /**
   * 对话记录点击绑定事件
   * @param {数据集} item
   * @param {索引} index
   */
  function dialogueBinding(item, index) {
    if (item.did !== dialogueList.value) {
      session.value.forEach(item => {
        item.active = null;
        item.menu = false;
        item.dropDownJudeg = true;
        item.Dstyle = null;
      });
      item.menu = true;
      item.dropDownJudeg = false;
      const judeg = transferStatus;
      initialize(judeg, judeg, judeg, judeg, judeg);
      session.value[index].active = 'chatgpt-dialogue-active';
      chatgptStyle.value.dialogueMessageRef['scroll-behavior'] = null;
      dialogueList.value = item.did;
      cookie.set('Dialogue', item.did);
      cookie.set('HeightScroll', 0);
      showDialogueAxios(dialogueList.value, true);
    }
  }


  /**
   * 外部点击关闭历史列表菜单菜单
   */
  function pageResetDataAll() {
    session.value.forEach(item => {
      if (!item.active) {
        item.menu = false;
        item.Dstyle = null;
        item.dropDownJudeg = true;
      }
    });
  }

  /**
   * 左侧菜单栏点击事件
   * @param {数据集} item
   */
  function dropDownBinding(item) {
    session.value.forEach(feItem => {
      if (!feItem.active) {
        if (feItem === item) {
          item.dropDownJudeg = !item.dropDownJudeg;
          item.Dstyle = { 'background-color': "rgba(53, 59, 72, 0.8)" };
        } else {
          feItem.menu = false;
          feItem.Dstyle = null;
          feItem.dropDownJudeg = true;
        }
      }
    });
  }

  /**
   * 显示单条对话
   * @param {记录id} did
   * @param {是否关闭左侧栏} judeg
   */
  function showDialogueAxios(did, judeg) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      loading1Control.value = true;
      loadingModule.value = true;
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      formData.append('username', userinfo.username);
      formData.append('did', did);
      axiosApi.routine('/chatgpt/inquire_dialogue', 'POST', true, formData, true)
        .then(result => {
          dialogueRecord.value = [];
          if (/^.+$/.test(result) && result !== 500) {
            const resArr = result.split(',');
            for (let i = 0; i < resArr.length; i++) {
              dialogueRecord.value.push(JSON.parse(decodeURIComponent(escape(atob(resArr[i])))));
            }
            messageHomeLogo.value = false;
          } else {
            newDialogue();
            session.value.forEach(item => {
              if (item.did === did) {
                item.active = 'chatgpt-dialogue-mistake';
                item.menu = true;
                item.dropDownJudeg = false;
              }
            });
          }
          nextTick(() => {
            const msht = cookie.get('HeightScroll');
            if (msht && msht !== '0') {
              dialogueMessageRef.value.scrollTop = msht;
            } else {
              dialogueMessageRef.value.scrollTop = dialogueMessageRef.value.scrollHeight;
            }
            copyText('chatgpt-copy-code-button');
          });
          loading1Control.value = false;
          loadingModule.value = false;
          if (judeg) { letfFarmeClose(true) };
          initialize(false, false, false, false, false);
        })
        .catch(error => {
          loading1Control.value = false;
          loadingModule.value = false;
          if (judeg) { letfFarmeClose(true) };
        });
    }
  }

  /**
   * 鼠标移入历史对话框
   * @param {数据集} item
   */
  function dialogueMouseenter(item) {
    if (dialogueInputJudeg && device === "PC") {
      item.menu = true;
    }
  }

  /**
   * 鼠标移出历史对话框
   * @param {数据集} item
   */
  function dialogueMouseleave(item) {
    if (item.dropDownJudeg) {
      item.menu = false;
      item.Dstyle = null
    }
  }

  /**
   * 对话记录点击事件
   * @param {数据集} item
   * @param {索引} index
   */
  function inputContentBinding(item, index) {
    if (dialogueInputJudeg) {
      dialogueBinding(item, index);
    }
  }

  /**
   * 历史记录修改焦点事件
   * @param {数据集} item
   * @param {索引} index
   */
  function updataDialogueTitleBlur(item, index) {
    if (/^.+$/.test(item.title) && item.title !== dialogueTitleList) {
      modificationTitle_axios(item.did, item.title);
    }
    if (item.active) {
      item.menu = true
    }
    dialogueInputJudeg = true;
    session.value[index].type = 'button';
    session.value[index].Istyle = { border: '1px solid transparent' };
    session.value[index].Dstyle = null;
  }

  /**
   * 列表重命名事件绑定
   * @param {数据集} item
   * @param {索引} index
   */
  function rewriteBinding(item, index) {
    dialogueInputJudeg = false;
    dialogueTitleList = item.title;
    session.value[index].menu = false
    session.value[index].type = 'text';
    session.value[index].Istyle = { border: '1px solid blue' };
    session.value[index].Dstyle = { 'background-color': 'rgba(53, 59, 72,0.8)' };
    nextTick(() => {
      jquery('#' + item.did).select();
    });
  }

  /**
   * 删除记录事件绑定
   * @param {数据集} item
   * @param {索引} index
   */
  function deleteBinding(item, index) {
    inquiryData.value = {
      message: '删除后不可恢复，请谨慎操作！',
      did: item.did,
      indexes: index
    };
  }

  /**
   * 删除对话回调
   * @param {组件返回值} params
   */
  function inquiryMonitor(params) {
    if (params.judeg) {
      delectDialogueAxios(params.data.did, params.data.indexes);
    }
    inquiryData.value = null;
  }

  /**
   * 删除历史对话记录
   * @param {记录id} pdid
   * @param {索引集} indexes
   */
  function delectDialogueAxios(pdid, indexes) {
    if (pdid === dialogueList.value) {
      newDialogue();
    }
    const formData = new FormData();
    formData.append('did', pdid);
    axiosApi.routine('/chatgpt/delete_dialogue', 'POST', true, formData, false);
    session.value.splice(indexes, 1);
  }

  /**
   * 修改记录标题
   * @param {记录id} did
   * @param {修改内容} content
   */
  function modificationTitle_axios(did, content) {
    const formData = new FormData();
    formData.append('did', did);
    formData.append('content', content);
    axiosApi.routine('/chatgpt/update_dialogue_title', 'POST', true, formData, false);
  }

  /**
   * 新建对话
   */
  function newDialogue() {
    const userinfo = commonUtils.emptyCookie('Userinfo', '请先登录');
    if (userinfo) {
      const formData = new FormData();
      formData.append('uid', userinfo.uid);
      axiosApi.routine('/chatgpt/judeg_new_dialogue', 'POST', false, formData, false)
        .then(result => {
          if (!result) {
            commonUtils.elmentMessage('会话列表超出上限', 'warning', 60);
          }
        });
      cookie.remove('dialogueList');
      const judeg = transferStatus;
      initialize(judeg, judeg, judeg, judeg, judeg);
      inputContentRef.value.focus();
      messageHomeLogo.value = true;
      dialogueList.value = null;
      dialogueRecord.value = [];
      dialogueIndex = dialogueRecord.value.length;
      session.value.forEach(item => {
        item.active = null;
      });
    }
    letfFarmeClose(true);
  }

  /**
   * 创建新对话到对话框
   * @param {数据集} data
   * @param {是否为延迟写入} judeg
   */
  function establishDialogue(data, judeg) {
    if (judeg) {
      const title = data.title;
      data.title = '';
      session.value.unshift(data);
      let count = 0;
      for (let i = 0; i < title.length; i++) {
        setTimeout(function() {
          session.value[0].title += title[i];
        }, count);
        count += 50;
      }
    } else {
      // session.value = []
      for (let i = 0; i < data.length; i++) {
        session.value.push({
          title: data[i].title,
          did: data[i].did,
          Dstyle: null,
          Istyle: { border: '1px solid transparent' },
          type: 'button',
          active: null,
          menu: false,
          dropDownJudeg: true
        });
      }
      loading1Control.value = false;
      if (data.length > 0 && historyRef.value.scrollHeight <= historyRef.value.clientHeight) {
        showDialogueAllAxios();
      }
    }
    session.value.forEach(item => {
      item.active = null;
      if (item.did === dialogueList.value) {
        item.active = 'chatgpt-dialogue-active';
        item.menu = true
        item.dropDownJudeg = false
      }
    });
  }

  /**
   * 监听页面是否处于后台
   */
  function visualizationMonitor() {
    // 是否在后台
    if (!document.hidden) {
      clearTimeout(webSocketTimeout.value);
      let websocketJudeg = false
      let refresh = cookie.get('Refresh');
      if (refresh) {
        refresh = JSON.parse(refresh);
        websocketJudeg = refresh[pageRandom] === undefined;
      } else {
        websocketJudeg = true;
      }
      userWhetherLogin(websocketJudeg);
    } else {
      webSocketTimeout.value = setTimeout(() => {
        if (websocket.value && websocket.value.readyState === 1) {
          websocket.value.close();
        }
      }, 120000);
    }
    commonUtils.addPageIdentification(pageRandom);
  }

  /**
   * 输入框监听保存样式
   * @param {event} event
   */
  function inputContentMonitor(event) {
    visibleTextarea = false;
    if (event.target.value.length > 0) {
      chatgptStyle.value.sendButton.border = '1px solid rgba(75, 75, 75,0.3)';
      chatgptStyle.value.sendButton.color = 'rgba(75, 75, 75,0.8)';
    } else {
      chatgptStyle.value.sendButton.border = '1px solid rgba(75, 75, 75,0.1)';
      chatgptStyle.value.sendButton.color = 'rgba(75, 75, 75,0.3)';
    }
    jquery('.chatgpt-input-content-box').css('height', 'auto');
    jquery('.chatgpt-input-content-box').css('height', inputContentRef.value.scrollHeight - 16 + 'px');
  }

  /**
   * 安全验证组件反馈
   * @param {组件返回值} params
   */
  function safetyMonitor(params) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (params.judeg) {
      jurisdictionTime = setTimeout(() => {
        jurisdictionTime = null;
      }, 600000);
      commonUtils.elmentMessage('验证成功，10分钟后需再次验证', 'info', 60);
    }
    safetyControl.value = false;
  }

  /**
   * mt端左侧栏操作
   */
  function letfFarmeBinding() {
    letfFarmeClose(true);
    leftFrameSwitch();
  }
</script>

<style scoped>
  @import "@/assets/css/chatgpt.css";
  @import "@/assets/css/universal.css";
  @import "@/assets/css/hljs/qualia.css";
</style>
